import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 
<path d="M1450 2218 c-17 -39 -18 -44 -3 -60 13 -15 14 -23 5 -43 -6 -14 -16
-25 -21 -25 -17 0 -33 29 -26 49 7 22 -35 61 -66 61 -18 0 -17 -3 6 -38 13
-20 27 -52 31 -70 6 -27 11 -32 35 -32 17 0 29 5 29 13 0 9 3 8 9 -2 7 -11 11
-10 20 5 10 18 12 18 21 2 15 -24 80 -25 80 -1 0 9 14 40 30 70 29 50 29 53
10 53 -32 0 -69 -42 -63 -72 5 -29 -7 -41 -31 -32 -18 7 -22 54 -5 71 8 8 5
22 -10 52 -12 22 -24 41 -27 41 -3 0 -14 -19 -24 -42z"/>
<path d="M1237 2070 c-14 -17 -29 -26 -32 -20 -9 15 -61 12 -75 -4 -14 -18
-10 -58 7 -64 18 -5 29 28 12 38 -11 7 -10 10 4 16 25 9 47 -3 47 -26 0 -28
-16 -57 -56 -103 l-34 -37 0 28 c0 17 -9 37 -21 48 -21 19 -22 19 -15 -4 9
-28 -19 -130 -44 -157 -22 -25 -34 -61 -22 -69 5 -3 6 -24 2 -48 -6 -31 -4
-48 6 -62 19 -28 18 -41 -3 -25 -17 13 -17 12 -2 -6 21 -26 33 -65 20 -65 -5
0 -13 5 -16 10 -4 6 -10 8 -14 5 -14 -8 -51 40 -53 71 -1 16 -8 38 -15 49 -7
11 -13 27 -13 37 0 10 -4 18 -10 18 -15 0 -12 -65 4 -104 8 -18 32 -48 55 -67
l42 -33 -32 -10 c-48 -16 -73 -56 -65 -100 13 -67 92 -87 118 -30 9 20 9 28
-4 41 -10 9 -24 13 -33 9 -21 -8 -14 -32 9 -30 19 2 21 -7 4 -24 -34 -34 -85
13 -73 68 8 35 30 50 74 50 25 0 47 -15 107 -71 42 -38 78 -68 81 -65 3 2 -19
33 -49 68 -77 91 -102 154 -103 253 0 97 18 138 104 231 40 44 62 78 74 117
20 62 36 80 69 75 31 -4 36 -35 6 -40 -27 -3 -31 -31 -6 -36 9 -2 24 8 34 23
13 21 15 31 6 50 -17 37 -63 34 -95 -5z"/>
<path d="M1630 2081 c-5 -11 -10 -26 -10 -34 0 -21 37 -50 55 -43 23 8 13 30
-14 34 -31 5 -23 36 10 40 30 4 51 -23 68 -86 7 -26 33 -64 71 -106 88 -97
105 -134 105 -231 0 -99 -26 -160 -109 -258 -31 -37 -52 -67 -47 -67 4 0 39
29 77 63 85 78 100 87 140 76 40 -11 62 -58 45 -95 -13 -29 -38 -41 -59 -27
-23 14 -26 31 -6 29 23 -2 30 22 9 30 -9 4 -23 0 -33 -9 -21 -22 -2 -64 33
-73 82 -21 114 115 35 152 -14 7 -37 18 -52 25 -16 7 -28 19 -28 26 0 8 5 11
13 7 9 -5 9 -4 1 5 -7 7 -9 22 -6 33 3 11 8 15 11 9 4 -6 8 21 8 60 1 38 5 74
9 80 3 6 -5 27 -18 47 -31 47 -62 137 -55 163 3 10 2 19 -2 19 -16 0 -31 -26
-31 -52 0 -28 -1 -28 -45 22 -34 39 -45 59 -45 85 0 37 15 48 44 33 14 -8 16
-12 6 -18 -16 -10 -5 -43 13 -38 17 6 21 46 7 64 -14 16 -66 19 -75 4 -3 -6
-18 3 -32 20 -30 36 -76 41 -93 11z"/>
<path d="M1390 2030 c0 -6 35 -10 84 -10 47 0 88 5 91 10 4 6 -27 10 -84 10
-54 0 -91 -4 -91 -10z"/>
<path d="M1387 1994 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M1460 1987 c0 -19 10 -27 26 -20 21 8 17 33 -6 33 -11 0 -20 -6 -20
-13z"/>
<path d="M1547 1994 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M1311 1966 c-9 -10 -8 -16 3 -25 10 -9 16 -8 25 3 9 10 8 16 -3 25
-10 9 -16 8 -25 -3z"/>
<path d="M1613 1964 c-8 -20 17 -36 34 -22 10 8 10 14 2 24 -15 18 -28 18 -36
-2z"/>
<path d="M1420 1939 c-141 -23 -249 -124 -286 -268 -20 -80 -13 -148 25 -227
46 -95 137 -173 221 -188 32 -6 30 -5 -11 12 -153 62 -238 195 -227 351 9 119
84 228 193 281 70 35 206 35 281 2 58 -26 133 -98 163 -158 94 -182 0 -407
-199 -477 -27 -9 -36 -16 -24 -16 37 -2 142 59 183 105 68 78 86 125 86 234 0
115 -19 163 -95 244 -81 86 -195 125 -310 105z"/>
<path d="M1243 1924 c-8 -20 8 -36 26 -25 15 10 8 41 -9 41 -6 0 -13 -7 -17
-16z"/>
<path d="M1683 1924 c-7 -18 17 -38 31 -24 11 11 1 40 -14 40 -6 0 -13 -7 -17
-16z"/>
<path d="M987 1896 c3 -20 -1 -54 -11 -83 -22 -63 -30 -142 -18 -172 7 -20 10
-10 15 51 8 90 13 105 38 111 23 6 25 34 5 81 -20 48 -36 54 -29 12z"/>
<path d="M1948 1896 c-24 -49 -23 -82 1 -89 14 -3 22 -20 31 -62 7 -31 10 -68
7 -82 -3 -15 0 -22 7 -20 18 6 18 75 0 130 -9 28 -20 76 -23 106 l-6 54 -17
-37z"/>
<path d="M1182 1873 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
-15 0 -20 -5 -18 -17z"/>
<path d="M1740 1870 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
<path d="M1470 1795 c0 -50 4 -85 10 -85 6 0 10 35 10 85 0 50 -4 85 -10 85
-6 0 -10 -35 -10 -85z"/>
<path d="M1436 1798 c24 -126 17 -125 -27 1 -13 35 -24 60 -26 54 -2 -6 10
-49 28 -94 18 -46 34 -78 37 -70 4 11 6 11 14 0 6 -10 7 -6 4 11 -3 14 -10 58
-16 98 -7 39 -15 72 -20 72 -4 0 -1 -33 6 -72z"/>
<path d="M1510 1790 c-17 -108 -7 -112 11 -5 9 47 12 85 8 85 -3 0 -12 -36
-19 -80z"/>
<path d="M1565 1839 c-24 -65 -44 -133 -41 -136 2 -2 15 29 30 68 15 39 24 74
22 77 -3 3 -8 -1 -11 -9z"/>
<path d="M1136 1825 c-6 -17 16 -31 33 -21 13 9 2 36 -14 36 -7 0 -16 -7 -19
-15z"/>
<path d="M1330 1838 c0 -4 21 -41 46 -83 25 -42 44 -78 42 -80 -2 -2 -23 20
-47 50 -24 29 -52 62 -64 72 -31 27 -11 -3 46 -69 l49 -58 -45 0 -45 0 1 -70
c0 -67 1 -70 25 -70 18 0 22 3 15 13 -16 21 -9 42 15 41 15 0 22 5 22 18 0 16
-3 17 -20 8 -23 -12 -22 -13 -25 18 -2 17 3 22 21 22 12 0 25 -6 27 -12 3 -7
6 -3 6 10 1 13 5 21 10 17 25 -15 19 21 -13 77 -37 67 -65 108 -66 96z"/>
<path d="M1579 1768 c-23 -40 -44 -76 -46 -80 -2 -5 -2 -8 1 -8 8 0 97 152 92
157 -2 2 -23 -28 -47 -69z"/>
<path d="M1784 1829 c-9 -16 12 -33 31 -25 14 5 15 9 5 21 -15 18 -26 19 -36
4z"/>
<path d="M1618 1758 c-67 -81 -76 -99 -19 -38 49 52 77 90 67 90 -2 0 -24 -24
-48 -52z"/>
<path d="M1651 1739 c-53 -43 -64 -67 -14 -31 30 22 80 72 71 72 -3 0 -29 -19
-57 -41z"/>
<path d="M1100 1750 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
<path d="M1305 1725 c28 -25 55 -45 60 -44 6 0 -12 20 -40 44 -27 25 -54 45
-60 44 -5 0 13 -20 40 -44z"/>
<path d="M1825 1760 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
<path d="M1230 1725 c0 -6 76 -45 87 -45 10 1 -69 50 -79 50 -5 0 -8 -2 -8 -5z"/>
<path d="M1675 1705 c-22 -13 -35 -25 -28 -25 12 0 83 39 83 46 0 9 -17 3 -55
-21z"/>
<path d="M2040 1680 c0 -11 -9 -29 -19 -39 -11 -11 -15 -21 -10 -23 6 -2 4
-19 -6 -41 -9 -23 -20 -35 -27 -31 -7 4 -8 3 -4 -5 4 -7 -2 -14 -15 -19 -12
-5 -18 -11 -12 -15 15 -9 84 52 99 89 16 39 19 104 4 104 -5 0 -10 -9 -10 -20z"/>
<path d="M1075 1680 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
<path d="M1850 1670 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
<path d="M1582 1658 c-20 -20 -13 -46 18 -68 17 -12 30 -28 30 -36 0 -19 -37
-18 -48 2 -7 12 -11 13 -17 4 -12 -19 9 -32 46 -28 24 2 37 10 44 26 8 19 17
22 67 22 32 0 58 3 58 8 0 4 -27 5 -60 4 -48 -3 -67 1 -95 18 -19 12 -35 25
-35 30 0 14 33 21 41 8 4 -7 11 -9 14 -6 12 12 -5 28 -29 28 -12 0 -27 -5 -34
-12z"/>
<path d="M1200 1632 c22 -13 107 -25 95 -13 -5 5 -32 12 -60 16 -32 4 -45 3
-35 -3z"/>
<path d="M1446 1624 c-12 -30 -6 -51 19 -64 19 -10 27 -10 39 -1 15 12 16 47
2 69 -11 17 -53 15 -60 -4z"/>
<path d="M1682 1628 c-24 -5 -42 -12 -39 -14 8 -8 107 8 107 17 0 11 -10 10
-68 -3z"/>
<path d="M1070 1590 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M1854 1599 c-3 -6 -2 -15 4 -21 13 -13 32 -2 32 18 0 16 -26 19 -36
3z"/>
<path d="M1218 1583 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M1237 1550 c-20 -4 -41 -10 -45 -14 -10 -11 91 4 103 15 10 10 -2 10
-58 -1z"/>
<path d="M1675 1553 c14 -14 75 -25 75 -13 0 6 -11 10 -24 10 -13 0 -33 3 -43
6 -12 5 -15 4 -8 -3z"/>
<path d="M870 1510 c24 -33 46 -38 76 -15 19 14 19 14 -1 15 -11 0 -29 7 -39
15 -11 8 -28 15 -39 15 -17 0 -17 -2 3 -30z"/>
<path d="M1393 1533 c4 -6 -17 -29 -47 -52 -29 -23 -62 -51 -72 -63 -19 -21
-19 -21 1 -10 11 5 47 35 79 64 45 41 56 57 46 63 -8 5 -11 4 -7 -2z"/>
<path d="M2054 1525 c-10 -8 -27 -15 -38 -15 -15 0 -16 -3 -6 -15 21 -25 57
-18 80 15 20 28 20 30 3 30 -11 0 -28 -7 -39 -15z"/>
<path d="M1302 1489 c-41 -22 -71 -43 -68 -46 5 -5 146 75 146 83 0 6 -2 6
-78 -37z"/>
<path d="M1550 1527 c0 -10 152 -138 157 -133 3 3 -9 18 -28 33 -67 57 -129
105 -129 100z"/>
<path d="M1655 1520 c7 -11 95 -40 95 -31 0 4 -23 14 -50 23 -28 9 -48 13 -45
8z"/>
<path d="M1359 1452 c-28 -32 -55 -66 -61 -77 -11 -20 -11 -20 10 -1 12 10 41
43 63 73 23 29 44 52 46 50 2 -3 -17 -39 -42 -82 -25 -42 -44 -79 -41 -82 5
-5 96 147 96 161 0 5 2 7 5 4 3 -3 -4 -31 -16 -62 -11 -32 -19 -59 -16 -62 2
-2 14 23 26 57 13 33 20 63 17 67 -18 17 -41 5 -87 -46z"/>
<path d="M1497 1465 c3 -27 9 -61 14 -75 l7 -25 1 25 c0 14 -6 48 -14 75 l-14
50 6 -50z"/>
<path d="M1515 1500 c-3 -4 4 -34 15 -66 29 -86 30 -87 36 -82 2 3 -4 29 -16
57 -32 83 -23 82 26 -2 25 -43 48 -77 50 -74 3 3 -16 40 -41 82 -25 43 -44 79
-42 81 2 2 29 -28 61 -67 32 -39 60 -68 63 -65 3 3 -22 36 -55 74 -55 62 -85
81 -97 62z"/>
<path d="M1660 1475 c30 -19 62 -35 70 -34 8 0 -12 15 -45 34 -78 45 -96 45
-25 0z"/>
<path d="M1445 1422 c-4 -24 -5 -46 -2 -49 8 -7 20 63 14 79 -2 7 -7 -6 -12
-30z"/>
<path d="M1470 1416 c0 -25 5 -46 10 -46 6 0 10 18 10 39 0 22 -4 43 -10 46
-6 4 -10 -12 -10 -39z"/>
<path d="M1410 1346 c-25 -11 -23 -39 4 -44 15 -3 18 0 13 17 -4 16 -1 21 14
21 20 0 26 -15 9 -25 -5 -3 -10 -18 -10 -33 0 -21 8 -31 38 -44 34 -16 34 -17
7 -12 -16 3 -52 7 -80 9 -35 1 -78 15 -139 45 -82 39 -95 42 -148 37 -34 -3
-72 -14 -93 -27 -36 -22 -50 -49 -17 -34 9 4 29 7 45 7 15 0 27 4 27 10 0 5 7
7 15 3 8 -3 14 0 12 7 -1 7 13 13 36 15 28 2 42 -2 55 -17 9 -11 51 -29 92
-41 100 -29 128 -52 38 -31 -22 5 -36 5 -32 0 3 -5 -12 -9 -33 -9 -21 0 -48
-3 -60 -6 -14 -4 -23 -2 -23 4 0 6 16 14 35 18 48 9 44 24 -7 26 -74 2 -92 2
-125 -6 -29 -7 -32 -5 -26 9 6 16 4 16 -21 0 -30 -20 -60 -59 -71 -94 l-7 -24
34 27 c33 25 177 81 185 72 3 -2 -4 -12 -15 -22 -25 -25 -42 -58 -42 -83 0
-28 14 -26 30 4 18 33 58 45 156 45 50 0 102 6 130 16 39 13 52 14 73 3 14 -6
80 -15 145 -18 l119 -7 27 -33 c17 -20 30 -28 35 -22 9 16 -4 51 -32 85 -13
16 -22 30 -21 32 4 4 110 -31 140 -46 13 -7 33 -22 46 -33 l22 -20 0 22 c0 28
-77 111 -88 94 -5 -8 -19 -9 -42 -5 -19 5 -42 7 -50 6 -8 0 -34 -2 -57 -2 -24
-1 -43 -7 -43 -12 0 -6 6 -10 13 -9 20 2 57 -13 57 -23 0 -5 -13 -7 -30 -3
-17 4 -68 7 -113 8 l-82 1 100 32 c65 21 101 38 103 48 3 12 15 16 48 16 37 0
44 -3 44 -20 0 -11 5 -20 11 -20 5 0 7 6 3 13 -6 8 -3 8 9 -1 9 -8 21 -11 26
-7 6 3 24 -1 41 -10 16 -8 30 -12 30 -7 0 14 -33 40 -73 57 -60 25 -114 19
-197 -21 -41 -20 -98 -42 -127 -49 -48 -12 -53 -11 -58 5 -3 9 -3 25 1 34 3 9
-1 27 -10 41 -16 24 -15 25 3 25 12 0 18 -6 17 -17 0 -10 7 -19 17 -21 20 -4
24 32 5 44 -18 11 -113 11 -138 0z"/>
<path d="M1419 1129 l-33 -42 29 5 c21 5 33 1 47 -16 18 -21 18 -21 33 -1 10
13 25 19 41 17 l25 -4 -24 39 c-18 28 -32 39 -54 41 -25 3 -36 -4 -64 -39z
m75 13 c7 -12 -4 -52 -14 -52 -12 0 -23 38 -15 50 7 12 22 13 29 2z"/>
<path d="M1365 1148 c-16 -6 -39 -17 -50 -24 -11 -8 -29 -14 -40 -14 -16 0
-17 -2 -5 -10 30 -20 70 -11 108 25 39 36 37 40 -13 23z"/>
<path d="M1582 1126 c38 -37 77 -46 108 -26 13 8 12 10 -3 10 -10 0 -34 8 -55
19 -20 10 -48 22 -62 25 -23 7 -21 4 12 -28z"/>
<path d="M700 1000 c0 -22 5 -40 10 -40 6 0 10 7 10 15 0 8 5 15 10 15 6 0 10
5 10 11 0 5 -4 7 -10 4 -5 -3 -10 0 -10 9 0 8 6 17 13 19 6 3 2 6 -10 6 -20 1
-23 -4 -23 -39z"/>
<path d="M765 1003 c3 -40 27 -56 49 -32 8 9 7 11 -6 6 -15 -5 -18 0 -18 28 0
23 -5 35 -13 35 -10 0 -13 -12 -12 -37z"/>
<path d="M842 1028 c-32 -32 18 -87 53 -58 28 23 12 70 -25 70 -9 0 -21 -5
-28 -12z m46 -26 c3 -17 -2 -22 -17 -22 -14 0 -21 6 -21 18 0 38 33 41 38 4z"/>
<path d="M933 1024 c-4 -10 0 -20 10 -26 21 -11 22 -32 2 -24 -8 3 -15 1 -15
-4 0 -15 26 -12 39 4 9 10 8 16 -4 26 -17 14 -21 35 -5 25 6 -3 10 -1 10 4 0
17 -30 13 -37 -5z"/>
<path d="M1000 1006 c0 -26 5 -36 20 -41 11 -3 24 -4 29 -1 11 7 20 76 9 76
-4 0 -8 -13 -8 -30 0 -20 -5 -30 -15 -30 -10 0 -15 10 -15 30 0 17 -4 30 -10
30 -5 0 -10 -15 -10 -34z"/>
<path d="M1090 1006 c0 -45 17 -47 23 -3 4 31 4 31 5 -6 1 -22 6 -36 12 -32 5
3 9 20 7 38 -2 24 -8 33 -25 35 -19 3 -22 -1 -22 -32z"/>
<path d="M1202 1028 c-20 -20 -14 -48 13 -60 21 -9 29 -9 42 5 9 9 14 20 10
24 -4 3 -13 -1 -20 -11 -11 -15 -15 -16 -25 -3 -17 20 -4 50 19 44 11 -3 19
-1 19 4 0 13 -44 11 -58 -3z"/>
<path d="M1290 1006 c0 -22 6 -36 16 -40 23 -9 34 -7 34 5 0 6 -7 9 -15 5 -12
-4 -15 3 -15 29 0 19 -4 35 -10 35 -5 0 -10 -15 -10 -34z"/>
<path d="M1360 1025 c-27 -32 23 -82 55 -55 20 16 19 56 -1 64 -25 10 -41 7
-54 -9z m46 -10 c4 -8 3 -23 0 -32 -6 -15 -8 -15 -22 -2 -8 9 -12 23 -8 32 8
21 23 22 30 2z"/>
<path d="M1458 1005 c4 -29 8 -35 28 -35 20 0 24 5 22 23 -5 32 -17 47 -37 47
-14 0 -16 -7 -13 -35z"/>
<path d="M1541 1009 c-14 -40 -14 -40 5 -32 9 3 20 0 24 -8 5 -7 11 -11 15 -8
5 6 -22 79 -29 79 -2 0 -9 -14 -15 -31z"/>
<path d="M1610 1000 c0 -36 3 -40 25 -40 14 0 25 5 25 11 0 6 -7 9 -15 5 -12
-4 -15 3 -15 29 0 19 -4 35 -10 35 -5 0 -10 -18 -10 -40z"/>
<path d="M1713 1024 c-4 -10 0 -20 10 -26 10 -5 17 -14 17 -20 0 -7 -5 -6 -15
2 -9 8 -15 9 -15 2 0 -16 16 -23 34 -16 21 8 20 30 -1 43 -16 9 -16 12 -3 21
13 8 12 10 -3 10 -10 0 -20 -7 -24 -16z"/>
<path d="M1780 1005 c0 -39 4 -42 26 -20 9 8 11 15 5 15 -6 0 -11 7 -11 16 0
10 6 14 15 10 8 -3 15 -1 15 4 0 6 -11 10 -25 10 -22 0 -25 -4 -25 -35z"/>
<path d="M1850 1006 c0 -20 5 -36 10 -36 6 0 10 5 10 12 0 6 7 4 18 -5 24 -22
35 -22 23 1 -5 10 -9 27 -8 37 2 15 -5 21 -25 23 -26 3 -28 1 -28 -32z"/>
<path d="M1930 1035 c0 -17 33 -66 39 -59 14 17 30 64 22 64 -5 0 -12 -10 -15
-22 l-7 -23 -9 23 c-8 19 -30 32 -30 17z"/>
<path d="M2020 1000 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40
-5 0 -10 -18 -10 -40z"/>
<path d="M2072 1028 c-16 -16 -15 -43 3 -58 16 -14 47 -10 52 6 2 7 -6 10 -22
6 -24 -4 -26 -2 -23 19 2 18 9 23 26 22 13 -2 21 1 17 7 -8 14 -38 13 -53 -2z"/>
<path d="M2150 1005 c0 -39 4 -42 26 -20 9 8 11 15 5 15 -6 0 -11 7 -11 16 0
10 6 14 15 10 8 -3 15 -1 15 4 0 6 -11 10 -25 10 -22 0 -25 -4 -25 -35z"/>
<path d="M2223 1025 c-3 -9 2 -22 13 -30 19 -14 18 -14 0 -15 -14 0 -17 -3
-10 -10 14 -14 44 -2 44 16 0 8 -7 17 -15 20 -8 4 -15 10 -15 16 0 5 5 6 10 3
6 -3 10 -1 10 4 0 17 -30 13 -37 -4z"/>
<path d="M894 919 c-8 -13 22 -46 33 -35 4 4 1 11 -7 17 -13 8 -13 10 0 19 13
8 13 10 -2 10 -9 0 -20 -5 -24 -11z"/>
<path d="M957 916 c-8 -20 20 -43 35 -28 14 14 3 42 -16 42 -7 0 -16 -6 -19
-14z"/>
<path d="M1025 913 c-7 -21 -7 -20 19 -20 17 0 22 4 19 17 -5 20 -30 22 -38 3z"/>
<path d="M1090 911 c0 -10 9 -21 20 -24 16 -4 20 0 20 17 0 15 -7 22 -20 23
-13 1 -20 -4 -20 -16z"/>
<path d="M1160 905 c0 -15 6 -25 15 -25 10 0 14 9 13 25 -1 14 -8 25 -15 25
-7 0 -13 -11 -13 -25z"/>
<path d="M1213 914 c-3 -9 0 -20 8 -24 17 -11 35 0 18 11 -12 8 -11 11 1 19
13 8 12 10 -3 10 -10 0 -20 -7 -24 -16z"/>
<path d="M1277 910 c8 -31 20 -33 25 -5 4 19 1 25 -12 25 -13 0 -17 -6 -13
-20z"/>
<path d="M1370 908 c0 -12 3 -18 7 -15 3 4 12 2 20 -4 10 -9 13 -6 13 13 0 17
-6 24 -20 25 -14 1 -20 -4 -20 -19z"/>
<path d="M1434 919 c-8 -14 21 -43 35 -35 17 11 13 29 -4 22 -8 -3 -15 -1 -15
3 0 5 6 12 13 14 7 3 6 6 -5 6 -9 1 -20 -4 -24 -10z"/>
<path d="M1537 910 c8 -31 20 -33 25 -5 4 19 1 25 -12 25 -13 0 -17 -6 -13
-20z"/>
<path d="M1590 909 c0 -14 5 -19 14 -16 8 3 17 1 20 -6 2 -7 5 2 5 18 1 23 -2
26 -9 15 -6 -10 -10 -11 -10 -2 0 6 -4 12 -10 12 -5 0 -10 -9 -10 -21z"/>
<path d="M1660 905 c0 -15 6 -25 15 -25 10 0 14 9 13 25 -1 14 -8 25 -15 25
-7 0 -13 -11 -13 -25z"/>
<path d="M1750 920 c1 -14 19 -32 28 -26 6 3 12 0 15 -6 3 -8 9 -3 15 12 11
28 6 42 -8 20 -6 -8 -10 -10 -10 -5 0 6 -9 11 -20 11 -11 0 -20 -3 -20 -6z"/>
<path d="M1842 908 c2 -13 10 -23 18 -23 8 0 16 10 18 23 3 17 -2 22 -18 22
-16 0 -21 -5 -18 -22z"/>
<path d="M1910 907 c0 -14 6 -24 15 -24 9 0 15 10 15 24 0 14 -6 23 -15 23 -9
0 -15 -9 -15 -23z"/>
<path d="M2020 911 c0 -27 19 -36 37 -18 18 18 9 37 -18 37 -12 0 -19 -7 -19
-19z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
